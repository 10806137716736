import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { SearchForm } from 'src/app/shared/models/elastic.vm.model';
import { Business } from '../../shared/models/business.model';
import { BusinessSearchResponse, BusinessTripSearchResponse } from '../../shared/models/business.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';

export const queryParamsChanged = createAction(
  '[Support agent business list] Query params changed',
  props<{ queryParams: Params }>(),
);

export const searchSubmitButtonClicked = createAction(
  '[Support agent business list] Search button clicked',
  props<{ searchForm: SearchForm }>(),
);

export const searchResponseFetched = createAction(
  '[Support agent business list] Search response fetched',
  props<{ searchResponse: SearchResponse<BusinessSearchResponse> }>(),
);

export const businessListFetched = createAction(
  '[Support agent business list] List fetched',
  props<{ businesses: Business[] }>(),
);

export const pageChanged = createAction(
  '[Support agent business list] Page changed',
  props<{ pageEvent: PageEvent }>(),
);

export const businessEditButtonClicked = createAction(
  '[Support agent business list] Business edit init',
  props<{ businessId: string }>(),
);

export const businessDetailsInit = createAction(
  '[Support agent business details] Business edit init',
  props<{ id: string }>(),
);

export const businessFetched = createAction(
  '[Support agent business details] Business fetched',
  props<{ business: Business }>(),
);

export const businessTripsFetched = createAction(
  '[Support agent business details] Business trip list fetched',
  props<{ trips: BookingAggregate[] }>(),
);

export const businessTripSearchResponseFetched = createAction(
  '[Support agent business details] Business trip search response fetched',
  props<{ searchResponse: SearchResponse<BusinessTripSearchResponse> }>(),
);

export const businessDetailsDestroyed = createAction('[Support agent business details] Business edit destroyed');

export const navigateToIntercomUserProfileButtonClicked = createAction(
  '[Support agent business details] Navigate to Intercom user profile button clicked',
  props<{ userId: string }>(),
);

export const allBusinessTripsButtonClicked = createAction(
  '[Support agent business details] Navigate to all business trips button clicked',
  props<{ businessId: string }>(),
);
