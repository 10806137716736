import { Params } from '@angular/router';
import { Action, createReducer, on } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { Business } from '../../shared/models/business.model';
import { BusinessSearchResponse, BusinessTripSearchResponse } from '../../shared/models/business.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { BusinessesActions } from './index';

export interface SupportAgentBusinessesState {
  isLoading: boolean;
  queryParams: Params;
  userPageSize: number;
  searchResponse: SearchResponse<BusinessSearchResponse>;
  businesses: Business[];
  businessId: string;
  business: Business;
  tripSearchResponse: SearchResponse<BusinessTripSearchResponse>;
  trips: BookingAggregate[];
}

export const initialState: SupportAgentBusinessesState = {
  isLoading: false,
  queryParams: null,
  userPageSize: null,
  searchResponse: null,
  businesses: [],
  businessId: null,
  business: null,
  tripSearchResponse: null,
  trips: [],
};

export const businessesReducer = createReducer(
  initialState,
  on(BusinessesActions.queryParamsChanged, (state, action) => ({
    ...state,
    queryParams: action.queryParams,
    isLoading: true,
  })),
  on(BusinessesActions.pageChanged, (state, action) => ({
    ...state,
    userPageSize: action.pageEvent.pageSize,
  })),
  on(BusinessesActions.searchResponseFetched, (state, action) => ({
    ...state,
    searchResponse: action.searchResponse,
  })),
  on(BusinessesActions.businessListFetched, (state, action) => ({
    ...state,
    businesses: action.businesses,
    isLoading: false,
  })),
  on(BusinessesActions.businessDetailsInit, (state, action) => ({
    ...state,
    isLoading: true,
    businessId: action.id,
  })),
  on(BusinessesActions.businessFetched, (state, action) => ({
    ...state,
    isLoading: false,
    business: action.business,
  })),
  on(BusinessesActions.businessTripsFetched, (state, action) => ({
    ...state,
    isLoading: false,
    trips: action.trips,
  })),
  on(BusinessesActions.businessTripSearchResponseFetched, (state, action) => ({
    ...state,
    tripSearchResponse: action.searchResponse,
  })),
  on(BusinessesActions.businessDetailsDestroyed, (state, _action) => ({
    ...state,
    isLoading: false,
    business: null,
    businessId: null,
    trips: [],
    tripSearchResponse: null,
  })),
);

export function reducer(state: SupportAgentBusinessesState, action: Action) {
  return businessesReducer(state, action);
}
